import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Link from 'site/components/Link';
import modelPropTypes, { tagAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import styles from './index.styl';


function TopicTags(props) {
  const {
    data,
    theme: {
      colors: {
        divider,
      },
      controls: {
        topicFooter: {
          links: linksAtoms,
        },
        topicTags: tagsAtoms,
      },
    },
  } = props;

  if (!data) return null;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.tag}
          margin-right ${linksAtoms.marginLeft}
          color ${linksAtoms.color}
          background ${divider}
          font ${tagsAtoms.font}
          :last-child
            margin-right 0
      `}</style>
    </scope>
  );

  return (
    <div className='topicTags'>
      {data.map(item => (
        <Link
          to={`/label/${item.attributes.slug}${item.attributes.tag_type === 'geo' ? '?type=stars' : ''}`}
          className={scoped.wrapClassNames(styles.tag)}
          type={linksAtoms.type}
          key={item.id}
        >
          {item.attributes.title}
        </Link>
      ))}
      <scoped.styles />
    </div>
  );
}

TopicTags.propTypes = {
  /**
   * Массив тегов.
   */
  data: PropTypes.arrayOf(modelPropTypes(tagAttributes).isRequired),
  theme: PropTypes.object,
};

export default withTheme(TopicTags);
